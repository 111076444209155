<template>
    <div>
        <v-chip
        :class="[`ma-2 ${doseTextColorMap}--text`]"
        :color="chipColor"
        small
        >
            <span class="subtitle-2">{{ doseText }}</span>
        </v-chip>
    </div>
</template>

<script>
export default {
    props: {
        doseType: {
            type: String,
            default: '',
            required: true
        },
    }, 
   computed: {
      chipColor() {
        if(this.doseType === 'FirstDoseOnly') {
          return 'firstDoseOnly';
        }
        else if(this.doseType === 'SecondDoseOnly') {
          return 'secondDoseOnly';
        } 
        else if(this.doseType === 'FirstAndSecondDose') {
          return 'firstAndSecondDose';  
        }
        else if(this.doseType === 'AllDoses') {
          return 'allDoses';  
        }
      },
      doseText() {
        if(this.doseType === 'FirstDoseOnly') {
          return '1st Dose Only';
        }
        else if(this.doseType === 'SecondDoseOnly') {
          return '2nd Dose Only';
        } 
        else if(this.doseType === 'FirstAndSecondDose') {
          return '1st & 2nd Doses';  
        }
        else if(this.doseType === 'AllDoses') {
          return 'All Doses';  
        }
      },
      doseTextColorMap() {
        if(this.doseType === 'FirstDoseOnly') {
          return 'firstDoseOnlyChipText';
        }
        else if(this.doseType === 'SecondDoseOnly') {
          return 'secondDoseOnlyChipText'; 
        } 
        else if(this.doseType === 'FirstAndSecondDose') {
          return 'firstAndSecondDoseChipText'; 
        }
        else if(this.doseType === 'AllDoses') {
          return 'allDosesChipText'; 
        }
       }
   },
}
</script>

<style>

</style>